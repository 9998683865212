<template>
  <footer class="footer">
    <span class="copyrights">
      {{copyText}}
    </span>
  </footer>  
</template>

<script>
export default {
  data() {
    return {
      copyText: `©  MULTIFACTOR ${new Date().getFullYear()}`
    }
  }
}
</script>

<style scoped lang="scss">
  .footer {
    text-align: center;
    padding: 15px 0;
  }
  .copyrights {
    display: inline-block;
    font-size: 15px;
    color: $gray;
  }
</style>